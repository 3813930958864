<template>
	<v-container fluid>
		<div class="d-flex flex-wrap mb-4" style="gap: 16px">
			<v-card
				width="100%"
				max-width="300"
				outlined
				v-for="topCard in situacionesEstudios"
				:key="topCard.texto"
				:loading="topCard.value === null"
			>
				<v-row class="pa-4">
					<v-col cols="auto" class="align-center d-flex">
						<v-avatar rounded="100" color="primary" size="50">
							<v-icon dark v-text="topCard.icon" />
						</v-avatar>
					</v-col>
					<v-col>
						<h2 v-if="topCard.value !== null" v-text="topCard.value + topCard.unit" />
						<span v-text="topCard.texto" />
					</v-col>
				</v-row>
			</v-card>
		</div>

		<v-row>
			<v-col cols="12" md="6">
				<doughnut-chart v-if="situacionesEstudios.length > 0" />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { getSituacionIcon } from '../utils';

export default {
	name: "Home",
	components: {
		DoughnutChart: () => import('@/components/DoughnutChart.vue')
	},
	data() {
		return {
			situacionesEstudios: []
		}
	},
	async mounted() {
		const { data: estudiosEstados } = await axios(`${process.env.VUE_APP_API_URL}/resumen.php`)

		// console.log(estudiosEstados)

		this.situacionesEstudios = estudiosEstados.map(x => ({
			icon: getSituacionIcon(x.estado).icon,
			texto: "Estudios " + x.estado,
			value: x.numero,
			unit: "",
		}))

		// this.situacionesEstudios.estudiosPendientes.value = (estudiosEstados.find(e => e.estado == 'Pendiente') || {}).numero || 0
		// this.situacionesEstudios.estudiosCompletados.value = (estudiosEstados.find(e => e.estado == 'Completado') || {}).numero || 0
		// this.situacionesEstudios.estudiosRechazados.value = (estudiosEstados.find(e => e.estado == 'Rechazado') || {}).numero || 0
	}
};
</script>
